<ng-container *ngIf="error && context">
    <div class="error-box" (mouseenter)="sendHighlight(true)" (mouseout)="sendHighlight(false)">
        <div class="header">
            <div class="preview" *ngIf="showContext" [innerHTML]="getContext(error.word) | highlight:error.word"></div>
            <div class="preview" *ngIf="!showContext">{{ error.word }}</div>
            <div class="toggle" [class.active]="isOpen" (click)="toggle()"><img src="assets/icons/chevron-down.svg" alt="Icon that indicates, if box is open"></div>
        </div>
        <div class="suggestions" *ngIf="isOpen">
            <div>Propostas:</div>
            <button *ngFor="let suggestion of suggestions" (click)="acceptSuggestion(suggestion)">{{ suggestion }}</button>
        </div>
    </div>
</ng-container>
