Idiom per la correctura:
<div class="select">
    <select name="language" id="language" [ngModel]="language" (ngModelChange)="languageSelected($event)">
        <option value="puter" disabled>Puter</option>
        <option value="rumgr" disabled>Rumantsch Grischun</option>
        <option value="surmiran">Surmiran</option>
        <option value="sursilvan" disabled>Sursilvan</option>
        <option value="sutsilvan" disabled>Sutsilvan</option>
        <option value="vallader" disabled>Vallader</option>
    </select>
</div>

<br>

<div class="toggle">
    <div class="toggler">
        <div class="check-box">
            <input type="checkbox" [ngModel]="showContext" (ngModelChange)="showContextChanged($event)">
        </div>
    </div>
    <div class="description">
        Mussar context
    </div>
</div>

<br>
<hr>
<br>

La controlla ortografica rumantscha è in project da la <a href="https://www.rumantsch.ch/" target="_blank">Pro Svizra Rumantscha</a>
realisà en collaboraziun cun la <a href="http://www.liarumantscha.ch" target="_blank">Lia Rumantscha</a>
e sustegnì da l'Uffizi federal da cultura.
