<div id="main-content">
    <div>
        <app-spellchecker *ngIf="selectedTab === 'spellchecker'"></app-spellchecker>
        <app-settings *ngIf="selectedTab === 'settings'"></app-settings>
    </div>
</div>

<div id="tabs">
    <app-tabs [selectedTab]="selectedTab" (tabChangedEvent)="tabChanged($event)"></app-tabs>
</div>
